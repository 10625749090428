.CleverAiPolicyPage {
    padding: 20px;
    background: #282c34;
    color: #fff;
}

.appButton {
    background-color: transparent;
    border: none;
}

.appButton:hover {
    background-color: transparent;
    border: none;
}
